import React from "react";
import AppcheckBase from "@src/layouts/AppcheckBase";
import queryString from "query-string";
import window from "global/window";

function AppcheckTeacherClassStory() {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { classId } = parsedLocation;

  return (
    <AppcheckBase>
      <a
        id="app-trigger"
        href={`https://www.classdojo.com/ul/t/class?classID=${classId}&target=story`}
        data-app={`https://www.classdojo.com/ul/t/class?classID=${classId}&target=story`}
        data-app-ios={`https://www.classdojo.com/ul/t/class?classID=${classId}&target=story`}
        data-app-android={`https://www.classdojo.com/ul/t/class?classID=${classId}&target=story`}
        data-store-android="com.classdojo.android"
        data-store-ios="552602056"
        style={{ color: "#fff" }}
      >
        ClassDojo App
      </a>
    </AppcheckBase>
  );
}

export default AppcheckTeacherClassStory;
